/**
 * Request API
 * <p>The Request API is used to manage requests</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Weekly  2 = Monthly  3 = Quarterly  4 = SemiAnnually  5 = Annually
 */
export const ShipmentFrequencyOptions = {
  Undefined: 0,
  Weekly: 1,
  Monthly: 2,
  Quarterly: 3,
  SemiAnnually: 4,
  Annually: 5,
} as const;

export type ShipmentFrequencyOptions =
  (typeof ShipmentFrequencyOptions)[keyof typeof ShipmentFrequencyOptions];

/**
 * @deprecated use EnumViewModel<ShipmentFrequencyOptions> instead.
 *
 * Maps a ShipmentFrequencyOptions enumeration value to its corresponding translation key.
 *
 * @param { ShipmentFrequencyOptions } shipmentFrequencyOptions -
 *        The enumeration value representing the type of ShipmentFrequencyOptions.
 * @returns {string} The translation key corresponding to the given ShipmentFrequencyOptions.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('ShipmentFrequencyOptions')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromShipmentFrequencyOptions(ShipmentFrequencyOptions.Weekly);
 * console.log(translationKey); // 'ShipmentFrequencyOptions.Weekly'
 * console.log(translocoService.translate(translationKey)) // "Translation of ShipmentFrequencyOptions.Weekly"
 *
 * // using in a template:
 * {{ translationKeyFromShipmentFrequencyOptions(ShipmentFrequencyOptions.Weekly) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('ShipmentFrequencyOptions')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromShipmentFrequencyOptions(
  shipmentFrequencyOptions: ShipmentFrequencyOptions,
) {
  switch (shipmentFrequencyOptions) {
    case ShipmentFrequencyOptions.Undefined:
      return 'ShipmentFrequencyOptions.Undefined';
    case ShipmentFrequencyOptions.Weekly:
      return 'ShipmentFrequencyOptions.Weekly';
    case ShipmentFrequencyOptions.Monthly:
      return 'ShipmentFrequencyOptions.Monthly';
    case ShipmentFrequencyOptions.Quarterly:
      return 'ShipmentFrequencyOptions.Quarterly';
    case ShipmentFrequencyOptions.SemiAnnually:
      return 'ShipmentFrequencyOptions.SemiAnnually';
    case ShipmentFrequencyOptions.Annually:
      return 'ShipmentFrequencyOptions.Annually';
    default:
      return '';
  }
}
