<div
  class="rounded border border-solid surface-neutral-light p-4 {{
    checked() ? 'border-brand-default' : 'border-neutral-default'
  }}"
>
  <div class="flex items-center gap-2">
    @if (showCheckMark()) {
      <mat-checkbox
        (change)="serviceChanged.emit($event.checked)"
        color="primary"
        [checked]="checked()"
        [disabled]="disabled()"
        aria-label="{{ title() }}"
      ></mat-checkbox>
    }
    <img class="mr-2 h-16 w-16" [alt]="title()" [src]="image()" />
    <div class="flex flex-col gap-1 text-neutral-body">
      <div class="flex items-center">
        <b>{{ title() }}</b>
      </div>
      <p class="text-sm text-neutral-subtitle">{{ description() }}</p>
    </div>

    <div class="ml-auto text-lg font-medium text-brand-default">
      @let price = this.price();
      @if (price && price > 0) {
        + {{ price | currency: currency() }}
      } @else {
        {{ includedText() }}
      }
    </div>
  </div>
  @let instructions = this.instructions();
  @if (instructions) {
    <p class="mt-4 text-sm text-neutral-caption">
      {{ instructions }}
    </p>
  }
</div>
