import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { type LaneRowsDetailsVehicleV2ViewModel } from '@cca-infra/request-management/v2';
import { UiLabelBadgeComponent } from '../badges';

@Component({
  selector: 'cca-vehicle-info-column',
  imports: [CommonModule, UiLabelBadgeComponent],
  templateUrl: './vehicle-info-column.component.html',
  styleUrl: './vehicle-info-column.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleInfoColumnComponent {
  readonly vehicles = input<LaneRowsDetailsVehicleV2ViewModel[]>([]);
}
