import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  type TrackByFunction,
  inject,
  viewChild,
  output,
  input,
  type AfterViewInit,
  computed,
  Injector,
} from '@angular/core';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { type TreeControl } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  type CollectionViewer,
  type DataSource,
} from '@angular/cdk/collections';
import {
  map,
  Observable,
  switchMap,
  tap,
  startWith,
  distinctUntilChanged,
  scan,
} from 'rxjs';
import { NgClass } from '@angular/common';
import { IconComponent } from '../../icon';
import { SpinnerComponent } from '../../spinner';
import { UiLabelBadgeComponent } from '../../badges';
import {
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';
import {
  type UserGroupViewModel,
  type UserGroupViewModelFlagToLabel,
} from './models';
import { toObservable } from '@angular/core/rxjs-interop';

/**
 * @deprecated If you intend to use this component for new features, be aware that `treeControl` is deprecated and will be removed in v21 ( end of 2025, start of 2026 ). Using this component would likely require a refactor later.
 */
@Component({
  selector: 'cca-collapsible-list-checkbox[virtual]',
  imports: [
    MatTreeModule,
    MatButtonModule,
    MatRadioModule,
    IconComponent,
    SpinnerComponent,
    MatCheckboxModule,
    NgClass,
    UiLabelBadgeComponent,
    ScrollingModule,
  ],
  templateUrl: './virtual-collapsible-list-checkbox.component.html',
  styleUrls: ['./virtual-collapsible-list-checkbox.component.scss'],
  // we specifically don't want onPush here, because it might be that the dataSource has a update for new data
  // but any input directly tied to this component is not updated, causing a change detection cycle to not update the view
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VirtualCollapsibleListCheckboxComponent implements AfterViewInit {
  private cdr = inject(ChangeDetectorRef);
  private injector = inject(Injector);

  readonly enterpriseLabel = input.required<string>();

  readonly flagToLabel = input.required<UserGroupViewModelFlagToLabel>();

  readonly response = output<UserGroupViewModel[]>();

  readonly treeControl = input.required<TreeControl<UserGroupViewModel>>();

  readonly dataSource = input.required<DataSource<UserGroupViewModel>>();

  readonly trackBy = input.required<TrackByFunction<UserGroupViewModel>>();

  readonly filter = input<(node: UserGroupViewModel) => boolean>(() => true);

  readonly disableCheckBox = input<(node: UserGroupViewModel) => boolean>(
    () => false,
  );

  readonly showSelectAll = input(false);

  readonly selectionChanged = output<UserGroupViewModel>();

  readonly tree = viewChild.required(MatTree);
  readonly viewPort = viewChild.required(CdkVirtualScrollViewport);
  _dataSource:
    | (DataSource<UserGroupViewModel> & {
        allItems: readonly UserGroupViewModel[];
      })
    | undefined;

  ngAfterViewInit(): void {
    const dataSource = this.dataSource();
    const viewPort = this.viewPort();
    const cdr = this.cdr;
    const injector = this.injector;
    const filterInput = this.filter;

    this._dataSource = new (class implements DataSource<UserGroupViewModel> {
      allItems: readonly UserGroupViewModel[] = [];

      connect(
        collectionViewer: CollectionViewer,
      ): Observable<readonly UserGroupViewModel[]> {
        const pageSize = 20;
        const itemSize = 50;
        return dataSource.connect(collectionViewer).pipe(
          switchMap((items) => {
            const computation = computed(() => {
              const filterFn = filterInput();
              return items.filter(filterFn);
            });
            return toObservable(computation, {
              injector: injector,
            });
          }),
          distinctUntilChanged((prev, cur) => {
            const prevSet = new Set([...prev.map((x) => x.id)]);
            const curSet = new Set([...cur.map((x) => x.id)]);

            if (prevSet.size === 0 && curSet.size === 0) {
              return true;
            }
            return (
              curSet.difference(prevSet).size === 0 &&
              prevSet.difference(curSet).size === 0
            );
          }),
          switchMap((items) => {
            this.allItems = items;

            viewPort.scrollToOffset(0);
            viewPort.setTotalContentSize(items.length * itemSize);

            return viewPort.elementScrolled().pipe(
              map((event) => event.currentTarget ?? event.target),
              map((eventTarget) => {
                const start = Math.floor(
                  (eventTarget as HTMLElement).scrollTop / itemSize,
                );
                const endIndex = start + pageSize;
                return endIndex;
              }),
              scan((currentEnd, next) => Math.max(currentEnd, next), 0),
              startWith(pageSize),
              distinctUntilChanged(),

              // below is for slowly rendering more rows outside our current view up to 10 more rows
              switchMap(
                (endIndex) =>
                  new Observable<number>((subscriber) => {
                    subscriber.next(endIndex);

                    let i = 0;
                    const next = () => {
                      i++;
                      subscriber.next(endIndex + i);

                      if (i < 10) {
                        requestAnimationFrame(() => {
                          setTimeout(next, 10);
                        });
                      } else {
                        subscriber.complete();
                      }
                    };
                    next();
                  }),
              ),
              scan((currentEnd, next) => Math.max(currentEnd, next), 0),
              distinctUntilChanged(),
              map((endIndex) => items.slice(0, endIndex)),
            );
          }),
          tap(() => cdr.markForCheck()),
        );
      }

      disconnect(collectionViewer: CollectionViewer): void {
        return dataSource.disconnect(collectionViewer);
      }
    })();
  }

  hasChild = (_: number, node: UserGroupViewModel) => node.hasChildren;

  checkboxToggle(checked: boolean, node: UserGroupViewModel) {
    node.selected = checked;
    this.selectionChanged.emit(node);
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: UserGroupViewModel): boolean {
    const descendants = this.treeControl().getDescendants(node);
    for (const child of descendants) {
      if (!child.selected) return false;
    }

    return true;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: UserGroupViewModel): boolean {
    const descendants = this.treeControl().getDescendants(node);
    const result = descendants.some((child) => child.selected);
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  itemSelectionToggle(checked: boolean, node: UserGroupViewModel): void {
    node.selected = checked;
    const descendants = this.treeControl().getDescendants(node);
    descendants?.forEach((child) => {
      if (!child.disabled && child.selected != checked) {
        child.selected = checked;
        this.selectionChanged.emit(child);
      }
    });
  }

  disableParent(node: UserGroupViewModel) {
    return (
      node.children?.length &&
      node.children?.every((child) => this.disableCheckBox()(child))
    );
  }
}
