import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type Observable } from 'rxjs';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type Filter,
  type FilterInfo,
  type AuditLogItemViewModel,
  type id,
  type ChainCargoAddress,
} from '@cca-infra/common';
import type {
  AddOrderSuppliersAsInactiveRequest,
  FilterChoiceOption,
  LaneOrderViewModel,
  OrderContactViewModel,
  OrderFileViewModel,
  OrderPaginationItemViewModel,
  PaginatedNetSuiteOrderExportRequest,
  // PaginationCarriersOrderViewModel,
  ResetOrderRequest,
  ResetReasonTypeViewModel,
  UpdateOrderCommentRequest,
  UpdateOrderShipperGroupRequest,
} from '../model';
import { type OrderResetStatusDerivedModel } from '@cca-infra/order-management/v2';

export interface OrderLocations {
  orderId: id;
  addresses: ChainCargoAddress[];
}

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseApiService {
  constructor() {
    super(`order.v1.orders`);
  }

  orderPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<OrderPaginationItemViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  orderLanePagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<LaneOrderViewModel>>(
      `${this.url}get-orders-by-lane`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  orderPaginationCount(filters: Filter[]): Observable<number> {
    return this.http.post<number>(`${this.url}paginated-count`, {
      filterDefinitions: filters,
    });
  }

  orderPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-filters`);
  }

  // suggestedCarrierPagination(
  //   paginationRequest: PaginationRequest,
  //   extraParams: PaginationRequestParameters,
  // ) {
  //   return this.http.post<PaginationCarriersOrderViewModel>(
  //     `${this.url}suggested-carriers-paginated`,
  //     {
  //       ...AdaptPaginationRequest(paginationRequest),
  //       ...extraParams,
  //     },
  //   );
  // }

  orderExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}paginated-export`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
        observe: 'response',
      },
    );
  }

  getOrderLocations(orderId: id) {
    return this.http.get<OrderLocations>(
      `${this.url}get-order-locations/${orderId}`,
    );
  }

  updateComments(orderId: id, comments: UpdateOrderCommentRequest) {
    return this.http.post<void>(`${this.url}update-comments`, {
      ...comments,
      orderId: orderId,
    });
  }

  cancelOrder(orderId: id | undefined) {
    return this.http.post<void>(`${this.url}cancel-order`, {
      orderId: orderId,
    });
  }

  resetOrder(resetOrderBody: ResetOrderRequest) {
    return this.http.post<void>(`${this.url}reset`, {
      ...resetOrderBody,
    });
  }

  getResetStatuses(orderId: string) {
    return this.http.get<OrderResetStatusDerivedModel[]>(
      `${this.url}reset-statusses/${orderId}`,
    );
  }

  getResetReasonTypes() {
    return this.http.get<ResetReasonTypeViewModel[]>(
      `${this.url}resetreasontypes`,
    );
  }

  updateOrderShipperGroup(update: UpdateOrderShipperGroupRequest) {
    return this.http.post<void>(`${this.url}update-order-shippergroup`, update);
  }

  getGeneratedPODTemplate(orderId: id) {
    return this.http.get(`${this.url}generate-pod/${orderId}`, {
      responseType: 'arraybuffer',
    });
  }

  getContacts(orderId: id) {
    return this.http.get<OrderContactViewModel[]>(
      `${this.url}contacts/${orderId}`,
    );
  }

  getPods(orderId: id, legId: id) {
    return this.http.get<OrderFileViewModel[]>(
      `${this.url}orderfiles-pod/${orderId}/${legId}`,
    );
  }

  getRejectedPods(orderId: id, legId: id) {
    return this.http.get<OrderFileViewModel[]>(
      `${this.url}orderfiles-rejected-pod/${orderId}/${legId}`,
    );
  }

  deleteFile(orderId: id, fileId: id) {
    return this.http.post(`${this.url}orderfiles/delete`, {
      orderId: orderId,
      fileIds: [fileId],
    });
  }

  deleteOrder(orderId: id) {
    return this.http.delete<boolean>(`${this.url}${orderId}`);
  }

  getOpenLaneOrders(laneId: id, from: number, to: number, carrierGroupId?: id) {
    return this.http.post<LaneOrderViewModel[]>(
      `${this.url}get-open-lane-orders`,
      {
        laneId: laneId,
        startDate: from,
        endDate: to,
        carrierGroupId: carrierGroupId,
      },
    );
  }

  massAssignCarriers(
    orderIds: id[],
    carrierGroupId: id,
    operatorId: id | undefined,
    carrierRate: number,
    estimatedFuelSurcharge: number | null,
  ) {
    return this.http.post<void>(`${this.url}mass-add-carrier`, {
      orderIds: orderIds,
      carrierGroupId: carrierGroupId,
      operatorId: operatorId,
      carrierRate: carrierRate,
      estimatedFuelSurcharge: estimatedFuelSurcharge,
    });
  }

  addOrderSuppliersAsInactive(body: AddOrderSuppliersAsInactiveRequest) {
    return this.http.post<void>(
      `${this.url}add-order-suppliers-as-inactive`,
      body,
    );
  }

  paginatedNetSuiteExport(body: PaginatedNetSuiteOrderExportRequest) {
    return this.http.post<void>(`${this.url}paginated-netsuite-export`, body);
  }

  getEventLog(orderId: id) {
    return this.http.get<AuditLogItemViewModel[]>(
      `${this.url}eventlog/${orderId}`,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }
}
