import { Injectable } from '@angular/core';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type id,
} from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import {
  type AssignToPlanningUnitRequest,
  type ConfirmPlanningRequest,
  type MoveStopsToPlanningUnitRequest,
  type PaginatedUnassignedEntitiesViewModel,
  type ResetDraftsRequest,
  type UnassignStopsFromPlanningUnitRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class PlanningService extends BaseApiService {
  constructor() {
    super(`trip.v1.planning`);
  }

  paginationUnassignedEntities(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
    ownerUserGroupId: id | null | undefined,
  ) {
    return this.http.post<PaginatedUnassignedEntitiesViewModel>(
      `${this.url}paginated-unassigned-entities`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
        ownerUserGroupId,
      },
    );
  }

  assignToPlanningUnit(body: AssignToPlanningUnitRequest) {
    return this.http.put<boolean>(`${this.url}assign-to-planning-unit`, body);
  }

  unassignStopsFromPlanningUnit(body: UnassignStopsFromPlanningUnitRequest) {
    return this.http.delete<boolean>(`${this.url}unassign-stops`, { body });
  }

  moveStopsToPlanningUnit(body: MoveStopsToPlanningUnitRequest) {
    return this.http.put<boolean>(`${this.url}move-stops`, body);
  }

  confirmPlanning(body: ConfirmPlanningRequest) {
    return this.http.put<boolean>(`${this.url}confirm-planning`, body);
  }

  resetDraftOrders(body: ResetDraftsRequest) {
    return this.http.put<boolean>(`${this.url}reset-drafts`, body);
  }

  confirmEntity(body: UnassignStopsFromPlanningUnitRequest) {
    return this.http.put<boolean>(`${this.url}confirm-entity`, body);
  }
}
