import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type MultiModalRequestHeaderDataViewModel,
  type MultimodalRequestDetailViewModel,
  type PaginationMultimodalRequestsViewModel,
} from '../model';
import {
  AdaptPaginationRequest,
  type Filter,
  type FilterInfo,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
} from '@cca-infra/common';
import { type Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MultimodalRequestService extends BaseApiService {
  constructor() {
    super(`request.v1.multimodal`);
  }

  getMultimodalRequestDetailByReference(refNumber: string) {
    return this.http.get<MultimodalRequestDetailViewModel>(
      `${this.url}details-by-reference/${refNumber}`,
    );
  }

  getRequestHeaderData(refNumber: string) {
    return this.http.get<MultiModalRequestHeaderDataViewModel>(
      `${this.url}header-data/${refNumber}`,
    );
  }

  requestPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<PaginationMultimodalRequestsViewModel>
    >(`${this.url}paginated`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  requestPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-filters`);
  }

  requestPaginationCount(filters: Filter[]): Observable<number> {
    return this.http.post<number>(`${this.url}paginated-count`, {
      filterDefinitions: filters,
    });
  }
}
