import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type StopType } from '@cca-infra/order-management/v2';
import {
  type DifotReasonCodeOptionViewModel,
  type LegDifotReasonCodeViewModel,
  type UpdateOrderDifotReasonRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class DifotService extends BaseApiService {
  constructor() {
    super(`order.v1.difot`);
  }

  getReasonCodeOptions(stopType: StopType) {
    return this.http.get<DifotReasonCodeOptionViewModel[]>(
      `${this.url}get-reason-code-options/${stopType}`,
    );
  }

  getOrderReasonCodes(orderId: string) {
    return this.http.get<LegDifotReasonCodeViewModel[]>(
      `${this.url}get-order-reason-codes/${orderId}`,
    );
  }

  updateOrderReasonCode(
    updateOrderDifotReasonBody: UpdateOrderDifotReasonRequest,
  ) {
    return this.http.post<boolean>(
      `${this.url}update-order-reason-code`,
      updateOrderDifotReasonBody,
    );
  }

  deleteOrderReasonCode(issueId: string) {
    return this.http.delete<boolean>(
      `${this.url}delete-order-reason-code/${issueId}`,
    );
  }
}
