<div
  class="item-center inline-flex gap-1 font-medium text-cca-label-lg"
  [ngClass]="flavor()"
>
  @let icon = this.icon();
  @if (icon) {
    <cca-icon [icon]="icon"></cca-icon>
  }
  <span>
    {{ text() }}
  </span>
</div>
