import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  type TrackByFunction,
  inject,
  viewChild,
  output,
  input,
} from '@angular/core';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { type TreeControl } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { type DataSource } from '@angular/cdk/collections';
import { NgClass } from '@angular/common';
import { IconComponent } from '../../icon';
import { SpinnerComponent } from '../../spinner';
import { UiLabelBadgeComponent } from '../../badges';
import {
  type UserGroupViewModelFlagToLabel,
  type UserGroupViewModel,
} from './models';

/**
 * @deprecated If you intend to use this component for new features, be aware that `treeControl` is deprecated and will be removed in v21 ( end of 2025, start of 2026 ). Using this component would likely require a refactor later.
 */
@Component({
  selector: 'cca-collapsible-list-checkbox:not([virtual])',
  imports: [
    MatTreeModule,
    MatButtonModule,
    MatRadioModule,
    IconComponent,
    SpinnerComponent,
    MatCheckboxModule,
    NgClass,
    UiLabelBadgeComponent,
  ],
  templateUrl: './collapsible-list-checkbox.component.html',
  styleUrls: ['./collapsible-list-checkbox.component.scss'],
  // we specifically don't want onPush here, because it might be that the dataSource has a update for new data
  // but any input directly tied to this component is not updated, causing a change detection cycle to not update the view
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CollapsibleListCheckboxComponent {
  changeDetectorRef = inject(ChangeDetectorRef);

  readonly enterpriseLabel = input.required<string>();

  readonly flagToLabel = input.required<UserGroupViewModelFlagToLabel>();

  readonly response = output<UserGroupViewModel[]>();

  readonly treeControl = input.required<TreeControl<UserGroupViewModel>>();

  readonly dataSource = input.required<DataSource<UserGroupViewModel>>();

  readonly trackBy = input.required<TrackByFunction<UserGroupViewModel>>();

  readonly filter = input<(node: UserGroupViewModel) => boolean>(() => true);

  readonly disableCheckBox = input<(node: UserGroupViewModel) => boolean>(
    () => false,
  );

  readonly showSelectAll = input(false);

  readonly selectionChanged = output<UserGroupViewModel>();

  readonly tree = viewChild.required(MatTree);

  hasChild = (_: number, node: UserGroupViewModel) => node.hasChildren;

  checkboxToggle(checked: boolean, node: UserGroupViewModel) {
    node.selected = checked;
    this.selectionChanged.emit(node);
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: UserGroupViewModel): boolean {
    const descendants = this.treeControl().getDescendants(node);
    for (const child of descendants) {
      if (!child.selected) return false;
    }

    return true;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: UserGroupViewModel): boolean {
    const descendants = this.treeControl().getDescendants(node);
    const result = descendants.some((child) => child.selected);
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  itemSelectionToggle(checked: boolean, node: UserGroupViewModel): void {
    node.selected = checked;
    const descendants = this.treeControl().getDescendants(node);
    descendants?.forEach((child) => {
      if (!child.disabled && child.selected != checked) {
        child.selected = checked;
        this.selectionChanged.emit(child);
      }
    });
  }

  disableParent(node: UserGroupViewModel) {
    return (
      node.children?.length &&
      node.children?.every((child) => this.disableCheckBox()(child))
    );
  }
}
