@let data = vehicleAndVolume();
@if (data) {
  <div class="text-base text-neutral-body">
    <div class="grid gap-2">
      <div class="flex flex-col gap-2">
        @for (vehicle of data.vehicles; track vehicle) {
          <div class="flex flex-col">
            <div class="font-medium">
              {{ vehicle.vehicleTypeName }}
            </div>
            <div class="flex flex-row gap-2">
              @for (badge of vehicle.bodyTypes; track badge) {
                <cca-label-badge
                  flavor="match"
                  [labelName]="badge?.bodyTypeName ?? ''"
                ></cca-label-badge>
              }
            </div>
          </div>
        }
        <div class="text-neutral-caption">
          {{ data.requestedVehicleAmount }}
          {{ frequency() | transloco | lowercase }}
        </div>
      </div>
    </div>
  </div>
}
