import {
  type HttpInterceptor,
  type HttpHandler,
  type HttpEvent,
  type HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  Injectable,
  computed,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { type Observable } from 'rxjs';
import { LoggedInSignalToken } from '../logged-in-signal';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

/**
 * Public Language interceptor adds a query param to every backend request, if we are not logged in to signal what language responses should be
 * when someone is logged in, the backend can resolve this by using the authentication data so there is no need for.
 */
@Injectable()
export class PublicPageInterceptor implements HttpInterceptor {
  private transloco = inject(TranslocoService);
  private loggedIn = inject(LoggedInSignalToken);

  // activated route never changes, it will be the root route however all we're interested in are the query params
  private route = inject(ActivatedRoute);
  private queryParams = toSignal(this.route.queryParams);
  private hash = computed(() => this.queryParams()?.hash ?? null);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.loggedIn()) {
      req = req.clone({
        params: req.params.append('lang', this.transloco.getActiveLang()),
      });

      const hash = this.hash();
      if (hash) {
        req = req.clone({
          headers: req.headers.append('CCAHash', this.hash()),
        });
      }
    }

    return next.handle(req);
  }
}

export function providePublicPageInterceptor() {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PublicPageInterceptor,
      multi: true,
    },
  ]);
}
