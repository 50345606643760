<ng-container *transloco="let t; read: 'richTextViewer'">
  @if (translatedText()) {
    @if (large()) {
      @if (title()) {
        <div class="flex flex-row items-center gap-2">
          <span class="text-xs text-neutral-caption">{{ title() }}</span>
          @if (internalTranslation()) {
            <cca-translate-button
              [smallSize]="true"
              (liveTranslateAction)="onLiveTranslation()"
            ></cca-translate-button>
          }
        </div>
      }
      <div
        class="surface-neutral-light/100 word-break relative overflow-auto"
        [ngClass]="styling()"
      >
        <cca-html-viewer [html]="translatedText()"></cca-html-viewer>
      </div>
    } @else {
      @defer {
        <div class="flex flex-col">
          @if (title()) {
            <div class="flex flex-row items-center gap-2">
              <span class="text-xs text-neutral-caption">{{ title() }}</span>
              @if (internalTranslation()) {
                <cca-translate-button
                  [smallSize]="true"
                  (liveTranslateAction)="onLiveTranslation()"
                ></cca-translate-button>
              }
            </div>
          }
          <div
            class="text-container surface-neutral-light/100 word-break relative"
            [class.expanded]="isExpanded"
            [ngClass]="styling()"
          >
            <cca-html-viewer
              [html]="translatedText()"
              (containerDimensionsChange)="
                onHtmlContainerDimensionsChange($event)
              "
            ></cca-html-viewer>
          </div>

          @if (isOverflow()) {
            <cca-show-more-less
              [showMoreLabel]="t('showMore')"
              [showLessLabel]="t('showLess')"
              [isShowMore]="!isExpanded"
              (isShowMoreChanged)="isExpanded = !isExpanded"
            >
            </cca-show-more-less>
          }
        </div>
      }
    }
  }
</ng-container>
