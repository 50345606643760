import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '../icon';
import { NgClass } from '@angular/common';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'cca-translate-button',
  imports: [TranslocoModule, MatButtonModule, IconComponent, NgClass],
  templateUrl: './translate-button.component.html',
  styleUrls: ['./translate-button.component.scss'],
  providers: [provideTranslocoScope('liveTranslate')],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslateButtonComponent {
  smallSize = input<boolean>(false);
  liveTranslateAction = output<boolean>();

  protected liveTranslateToggle = false;

  onLiveTranslate() {
    this.liveTranslateToggle = !this.liveTranslateToggle;
    this.liveTranslateAction.emit(this.liveTranslateToggle);
  }
}
