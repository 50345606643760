import { NgClass } from '@angular/common';
import { Component, input, ChangeDetectionStrategy } from '@angular/core';
import { type CtrlChainIcons, type StatusBadgeFlavor } from '@cca-common/core';
import { IconComponent } from '../../icon';

@Component({
  imports: [NgClass, IconComponent],
  selector: 'cca-text-badge',
  styleUrls: ['./text-badge.component.scss'],
  templateUrl: './text-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTextBadgeComponent {
  readonly flavor = input<StatusBadgeFlavor>('primary');
  readonly text = input<string | number | boolean>('');
  readonly icon = input<CtrlChainIcons>();
}
