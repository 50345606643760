import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type AuditLogItemViewModel,
  type id,
  type FilterChoiceOption,
} from '@cca-infra/common';
import {
  type ManageOperatorRequest,
  type OrderRequestViewModel,
  type RequestContactViewModel,
  type ShippingRequirementViewModel,
  type UpdateRequestCommentRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class RequestService extends BaseApiService {
  constructor() {
    super(`request.v1.request`);
  }

  updateComments(requestId: id, comments: UpdateRequestCommentRequest) {
    return this.http.post<void>(`${this.url}update-comments`, {
      ...comments,
      requestId: requestId,
    });
  }

  updateOperator(request: ManageOperatorRequest) {
    return this.http.post(`${this.url}manage-operator`, request);
  }

  getOrders(requestId: id) {
    return this.http.get<OrderRequestViewModel[]>(
      `${this.url}get-orders/${requestId}`,
    );
  }

  getContacts(requestId: id) {
    return this.http.get<RequestContactViewModel[]>(
      `${this.url}contacts/${requestId}`,
    );
  }

  getEventLog(requestId: id) {
    return this.http.get<AuditLogItemViewModel[]>(
      `${this.url}eventlog/${requestId}`,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  syncFromThirdParty(requestId: id) {
    return this.http.get<boolean>(
      `${this.url}sync-from-third-party/${requestId}`,
    );
  }

  getAllShippingRequirements() {
    return this.http.get<ShippingRequirementViewModel[]>(
      `${this.url}get-all-shipping-requirements/`,
    );
  }
}
