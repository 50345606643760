import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  input,
} from '@angular/core';
import {
  AnimationLoader,
  type AnimationOptions,
  LottieComponent,
  provideCacheableAnimationLoader,
  provideLottieOptions,
} from 'ngx-lottie';

@Component({
  imports: [LottieComponent],
  providers: [
    provideLottieOptions({
      // when deployed lottie-web does not work when the player is loaded without `then((x) => x)`
      player: () => import('lottie-web').then((x) => x),
    }),
    provideCacheableAnimationLoader(),
    AnimationLoader,
  ],
  selector: 'cca-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.inline-grid]': 'inline()',
  },
})
export class SpinnerComponent {
  inline = input(true);

  options: AnimationOptions = {
    // note, this file might be cached by browsers if we ever change this we would need a new name such that the browser thinks it is different file
    path: 'assets/icons/logo.json',
  };
}
