import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { type CtrlChainIcons, type StatusBadgeFlavor } from '@cca-common/core';
import { IconComponent } from '../../icon';

@Component({
  imports: [NgClass, IconComponent],
  selector: 'cca-label-badge',
  styleUrls: ['./label-badge.component.scss'],
  templateUrl: './label-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiLabelBadgeComponent {
  readonly flavor = input<StatusBadgeFlavor>('primary');
  readonly labelName = input<string | number | boolean>('');
  readonly icon = input<CtrlChainIcons>();
}
