import {
  inject,
  Injectable,
  type OnDestroy,
  Pipe,
  type PipeTransform,
} from '@angular/core';
import { BaseLocalePipe, type Locale } from '@jsverse/transloco-locale';

// TODO: remove when Intl.DurationFormat is baseline stable
// @ts-expect-error check for durationFormat if it does not exist force load the polyfill
if (!Intl?.DurationFormat) {
  import('@formatjs/intl-durationformat/polyfill-force');
}

interface DateDurationInput {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
  microseconds?: number;
  nanoseconds?: number;
}

@Injectable({
  providedIn: 'root',
})
export class DateDurationPipeFormatterCache {
  private formatterCache = new Map<string, Intl.RelativeTimeFormat>();

  resolveFormatter(locale: string) {
    // check if a formatter exists within the currencyFormatterMap
    // create a new one if it does not
    if (!this.formatterCache.has(locale)) {
      return this.createAndStoreFormatter(locale);
    }

    // return the formatter from the map
    return this.formatterCache.get(locale) as Intl.RelativeTimeFormat;
  }

  private createAndStoreFormatter(locale: string) {
    // create new number formatter
    // @ts-expect-error this does not exist in type defintions..
    const formatter = new Intl.DurationFormat(locale, {
      style: 'long',
    });

    // save the formatter
    this.formatterCache.set(locale, formatter);

    // return reference to the formatter
    return formatter;
  }
}

@Pipe({
  name: 'ccaDuration',
  pure: true,
})
export class CdkDurationPipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  private formatterCache = inject(DateDurationPipeFormatterCache);

  transform(value: DateDurationInput, locale?: Locale | null | undefined) {
    return this.format(value, this.getLocale(locale ?? undefined));
  }

  format(duration: DateDurationInput, locale: string) {
    // resolve formatter
    const formatter = this.formatterCache.resolveFormatter(locale);

    return formatter.format(duration);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
