import { inject } from '@angular/core';
import {
  AddressService,
  type LocationTypeViewModel,
} from '@cca-infra/location-management/v1';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { exhaustMap, pipe } from 'rxjs';
import { withDevTracking } from './util';

type LocationOptionState = {
  availableLocations: LocationTypeViewModel[];
};

export const LocationOptionsStore = signalStore(
  {
    providedIn: 'root',
  },
  withState<LocationOptionState>({
    availableLocations: [],
  }),
  withMethods((store) => {
    const addressService = inject(AddressService);
    return {
      loadLocationOptions: rxMethod<void>(
        pipe(
          exhaustMap(() =>
            addressService.getLocationTypes().pipe(
              tapResponse(
                (locationTypes) => {
                  patchState(store, () => ({
                    availableLocations: locationTypes.sort(
                      (a, b) => a.value - b.value,
                    ),
                  }));
                },
                (err) => {
                  console.error(`failed to load location types`, err);
                },
              ),
            ),
          ),
        ),
      ),
    };
  }),
  withHooks({
    onInit(store) {
      store.loadLocationOptions();
    },
  }),
  withDevTracking('LocationOptionsStore'),
);
