@let timeOptionType = this.timeOptionType();
<div class="flex flex-col gap-1 whitespace-nowrap" *transloco="let t">
  <p
    class="col-span-3 col-start-2 text-xs text-neutral-caption"
    [class.ml-6]="showClockIcon()"
  >
    @if (timeslotRequired()) {
      {{ t('timeWindow.timeOption.timeSlot') }} -
      @if (timeslotPreAgreed()) {
        <span>
          {{ t('TimeSlotsStatus.Confirmed') }}
        </span>
      }
      @if (!timeslotPreAgreed()) {
        <span class="warning-text-dark">
          {{ t('TimeSlotsStatus.Pending') }}
        </span>
      }
    } @else {
      {{ timeOptionType ? timeOptionTranslation(timeOptionType) : label() }}
    }
  </p>
  <div class="flex shrink-0 gap-2 text-neutral-body">
    @if (showClockIcon()) {
      <cca-icon
        [icon]="hideTime() ? 'calendar-1' : 'clock'"
        class="col-span-1 col-start-1 row-span-1 row-start-2 w-4 self-start justify-self-center leading-[18px] text-neutral-body"
      />
    }

    @let timeWindowStart = this._timeWindowStart();
    @let timeZoneStart = timeZoneIdStart();
    @if (timeWindowStart) {
      <span
        class="col-span-1 col-start-4 row-span-1 row-start-2 shrink-0"
        [ngxTippy]="dateTemplate"
        [tippyClassName]="'cca-tippy'"
        [tippyContext]="{ data: timeWindowStart, timezone: timeZoneStart }"
        [tippyProps]="{
          placement: 'bottom',
          arrow: true,
          theme: 'dark',
          interactive: true,
          appendTo: body,
          zIndex: 999999,
        }"
      >
        {{ timeWindowStart | ccaDate: 'ccc,' : timeZoneStart }}
        <span>
          @if (showTimeOneLine()) {
            {{ timeWindowStart | ccaDate: 'DD • t' : timeZoneStart }}
          } @else if (hideTime()) {
            {{ timeWindowStart | ccaDate: 'DD' : timeZoneStart }}
          } @else {
            {{ timeWindowStart | ccaDate: 'DD' : timeZoneStart }}
            <br />
            {{ timeWindowStart | ccaDate: 't' : timeZoneStart }}
          }
        </span>
      </span>
    } @else if (unknownDateText()) {
      <span class="col-span-1 col-start-4 row-span-1 row-start-2 shrink-0">
        <span class="text-neutral-body">{{ unknownDateText() }}</span>
      </span>
    } @else {
      <span class="col-span-1 col-start-4 row-span-1 row-start-2 shrink-0">
        <span class="text-neutral-body">{{
          t('TimeSlotsStatus.Pending')
        }}</span>
      </span>
    }

    @if (
      timeOptionType === TimeIndicationType.Between ||
      timeslotRequired() ||
      isRange()
    ) {
      <cca-icon
        icon="arrow-right"
        class="col-span-1 col-start-3 row-span-1 row-start-2 w-4 self-start text-neutral-body"
      />

      @let timeWindowEnd = this._timeWindowEnd();
      @let timeZoneEnd = timeZoneIdEnd();
      @if (timeWindowEnd) {
        <span
          class="col-span-1 col-start-4 row-span-1 row-start-2 shrink-0"
          [ngxTippy]="dateTemplate"
          [tippyClassName]="'cca-tippy'"
          [tippyContext]="{ data: timeWindowEnd, timezone: timeZoneEnd }"
          [tippyProps]="{
            placement: 'bottom',
            arrow: true,
            theme: 'dark',
            interactive: true,
            appendTo: body,
            zIndex: 999999,
          }"
        >
          {{ timeWindowEnd | ccaDate: 'ccc,' : timeZoneEnd }}
          <span>
            @if (showTimeOneLine()) {
              {{ timeWindowEnd | ccaDate: 'DD • t' : timeZoneEnd }}
            } @else if (hideTime()) {
              {{ timeWindowEnd | ccaDate: 'DD' : timeZoneStart }}
            } @else {
              {{ timeWindowEnd | ccaDate: 'DD' : timeZoneEnd }}
              <br />
              {{ timeWindowEnd | ccaDate: 't' : timeZoneEnd }}
            }
          </span>
        </span>
      } @else {
        <span class="col-span-1 col-start-4 row-span-1 row-start-2 shrink-0">
          <span class="text-neutral-body">{{
            t('TimeSlotsStatus.Pending')
          }}</span>
        </span>
      }
    }
  </div>
  @if (showRemainingMessage()) {
    <div class="pl-6">
      <ng-container>
        <span class="critical-text-light">
          {{ _timeWindowStart() | ccaDateRelative }}</span
        >
      </ng-container>
    </div>
  }
</div>

<ng-template #dateTemplate let-name let-data="data" let-timezone="timezone">
  <div class="!flex flex-col gap-1">
    <span>
      {{ data | ccaDate: 'DD' : timezone }},
      {{ data | ccaDate: 't' : timezone }}
    </span>
    <span>
      {{ data | ccaDate: 'z' : timezone }}
    </span>
  </div>
</ng-template>
