import {
  Component,
  input,
  computed,
  ChangeDetectionStrategy,
} from '@angular/core';
import { type StatusBadgeFlavor } from '@cca-common/core';

@Component({
  selector: 'cca-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiStatusBadgeComponent {
  readonly flavor = input<StatusBadgeFlavor | undefined>();

  protected readonly currentFlavor = computed(() => {
    return this.flavor() ?? 'primary';
  });
}
