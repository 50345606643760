<cdk-virtual-scroll-viewport appendOnly class="h-full" itemSize="50">
  <!-- <ng-container
    *cdkVirtualFor="let item of _dataSource?.allItems"
  ></ng-container> -->

  <mat-tree
    [dataSource]="_dataSource ?? []"
    [treeControl]="treeControl()"
    class="list-none"
  >
    <ng-container *matTreeNodeDef="let node">
      <mat-nested-tree-node>
        <li
          class="mat-tree-node pl-12 text-base font-medium text-neutral-title"
          [ngClass]="node.isDefault || !showSelectAll() ? 'pl-12' : 'pl-20'"
        >
          <mat-checkbox
            class="pointer-events-auto"
            color="primary"
            (change)="checkboxToggle($event.checked, node)"
            [checked]="node.selected"
            [disabled]="disableCheckBox()(node)"
          >
            <span class="text-base font-medium">
              {{ node.name }}
            </span>
            @if (node.isEnterprise) {
              <span class="text-base font-normal text-neutral-caption">
                ({{ enterpriseLabel() }})
              </span>
            }
          </mat-checkbox>

          @for (flag of node.flags; track flag) {
            <cca-label-badge
              class="ml-2"
              [flavor]="flag.flavor"
              [labelName]="flagToLabel()(flag)"
            ></cca-label-badge>
          }
        </li>
      </mat-nested-tree-node>
    </ng-container>

    <ng-container *matTreeNodeDef="let node; when: hasChild">
      <mat-nested-tree-node [class.!hidden]="!filter()(node)">
        <div
          class="mat-tree-node flex border-y border-neutral-default"
          [ngClass]="{
            'surface-neutral-default': treeControl().isExpanded(node),
          }"
          matTreeNodeToggle
        >
          <button
            mat-icon-button
            [attr.aria-label]="'toggle ' + node.name"
            type="button"
          >
            <cca-icon
              class="text-xl text-brand-default"
              [icon]="
                treeControl().isExpanded(node) ? 'chevron-up' : 'chevron-right'
              "
            >
            </cca-icon>
          </button>
          @if (showSelectAll()) {
            <mat-checkbox
              class="pointer-events-auto"
              color="primary"
              (click)="$event.stopImmediatePropagation()"
              [checked]="descendantsAllSelected(node)"
              [indeterminate]="descendantsPartiallySelected(node)"
              (change)="itemSelectionToggle($event.checked, node)"
              [disabled]="disableParent(node)"
            >
            </mat-checkbox>
          }

          <span class="cursor-pointer text-base font-medium text-neutral-body">
            {{ node.name }} ({{ node.childCount }})
          </span>
        </div>

        @if (node.loading) {
          <cca-spinner class="h-8 w-8"></cca-spinner>
        }

        <ul [class.hidden]="!treeControl().isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </mat-nested-tree-node>
    </ng-container>
  </mat-tree>
</cdk-virtual-scroll-viewport>
