import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type MultimodalOrderHeaderViewModel,
  type FilterInformation,
  type PaginationMultimodalOrdersViewModel,
  type UpdateFCLAdditionalInformationRequest,
  type UpdateLegRequest,
  type UpdateOrderModalityReferencesRequest,
  type UpdateShipperReferenceRequest,
} from '../model';
import { type MulitmodalOrderDetailDerivedViewModel } from '../derived';
import { map } from 'rxjs';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
} from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class MultimodalOrderService extends BaseApiService {
  constructor() {
    super(`order.v1.multimodal`);
  }

  getHeaderData(referenceNumber: string) {
    return this.http.get<MultimodalOrderHeaderViewModel>(
      `${this.url}header-data/${referenceNumber}`,
    );
  }

  updateLegLocation(leg: UpdateLegRequest) {
    return this.http.post<boolean>(`${this.url}update-leg`, leg);
  }

  multimodalOrderPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<PaginationMultimodalOrdersViewModel>
    >(`${this.url}paginated`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  multimodalOrderPaginationFilters() {
    return this.http.get<FilterInformation[]>(`${this.url}paginated-filters`);
  }

  multimodalUpdateReferences(references: UpdateOrderModalityReferencesRequest) {
    return this.http.post<boolean>(`${this.url}update-references`, references);
  }

  multimodalUpdateShipperReference(reference: UpdateShipperReferenceRequest) {
    return this.http.post<boolean>(
      `${this.url}update-shipper-reference`,
      reference,
    );
  }

  multimodalUpdateAdditionalInformation(
    additionalInformation: UpdateFCLAdditionalInformationRequest,
  ) {
    return this.http.post<boolean>(
      `${this.url}update-additional-information`,
      additionalInformation,
    );
  }

  getMultimodalOrderDetailByReference(refNumber: string) {
    return this.http
      .get<MulitmodalOrderDetailDerivedViewModel>(
        `${this.url}details-by-reference/${refNumber}`,
      )
      .pipe(
        //mapping multimodal order detail props to v2 order detail so that actions work in both
        map((x) => {
          x.stops = x?.orderModalities?.at(0)?.stops?.map((stop: any) => {
            stop.locationId = stop.address.locationId;
            stop.timeZoneId = stop.address.timeZoneId;
            return stop;
          });
          x.requestedVehicleTypes =
            x?.orderModalities?.at(0)?.requestedVehicleTypes;
          x.carrierGroupId = x?.orderModalities?.at(0)?.supplierGroupId;
          x.legViewModels = x?.orderModalities
            ?.at(0)
            ?.stops?.map((stop: any) => {
              stop.legId = stop.id;
              stop.locationId = stop.address.locationId;
              stop.timeSlotIsRequired = stop.timeslotRequired;
              stop.locationComment = stop.instructions;
              return stop;
            });
          return x;
        }),
      );
  }
}
