<ng-container *transloco="let t; read: 'liveTranslate'">
  <button
    mat-button
    color="primary"
    class="button-small"
    [ngClass]="{ 'icon-button-extra-small-with-text': smallSize() }"
    (click)="onLiveTranslate()"
  >
    <cca-icon
      class="mr-2"
      [icon]="liveTranslateToggle ? 'refresh' : 'translation'"
    ></cca-icon>
    {{ liveTranslateToggle ? t('showOriginal') : t('translate') }}
  </button>
</ng-container>
