import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ShipmentFrequencyOptions,
  type LaneRowsDetailsVehicleV2ViewModel,
} from '@cca-infra/request-management/v2';
import { UiLabelBadgeComponent } from '../badges/label-badge';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { TranslocoModule } from '@jsverse/transloco';

interface VehicleAndVolume {
  vehicles: LaneRowsDetailsVehicleV2ViewModel[];
  requestedVehicleAmount: number;
  shipmentFrequencyOption: ShipmentFrequencyOptions;
}

@Component({
  selector: 'cca-multi-vehicle-stacked',
  imports: [CommonModule, UiLabelBadgeComponent, TranslocoModule],
  templateUrl: './multi-vehicle-stacked.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiVehicleStackedComponent {
  vehicleAndVolume = input<VehicleAndVolume>();
  frequency = computed(() => {
    const shipmentFrequencyOption =
      this.vehicleAndVolume()?.shipmentFrequencyOption;
    if (shipmentFrequencyOption) {
      return this.translateFrequencyOptions(shipmentFrequencyOption);
    }
    return '';
  });

  protected translateFrequencyOptions(value?: ShipmentFrequencyOptions) {
    switch (value) {
      case ShipmentFrequencyOptions.Weekly:
        return t('sequenceStep.frequency.weekly');
      case ShipmentFrequencyOptions.Monthly:
        return t('sequenceStep.frequency.monthly');
      case ShipmentFrequencyOptions.Quarterly:
        return t('sequenceStep.frequency.quarterly');
      case ShipmentFrequencyOptions.SemiAnnually:
        return t('sequenceStep.frequency.semiannually');
      case ShipmentFrequencyOptions.Annually:
        return t('sequenceStep.frequency.annually');
    }
    return '';
  }
}
