import { BaseApiService } from '@cca-infra/core';
import { Injectable } from '@angular/core';
import { type RequestDetailV2ViewModel } from '../model';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
} from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class RequestV2Service extends BaseApiService {
  constructor() {
    super(`request.v2.request`);
  }

  getRequestDetailByReference(reference: string) {
    return this.http.get<RequestDetailV2ViewModel>(
      `${this.url}details-by-reference/${reference}`,
    );
  }

  requestExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}paginated-export`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
        observe: 'response',
      },
    );
  }
}
