/// <reference types="@types/google.maps" />
import {
  type AddressViewModel,
  type ChainCargoAddress,
  LocationSource,
} from '@cca-infra/common';
import * as frag from '@fragaria/address-formatter';

// There is a weird conflict when running in dev-server, where `@fragaria/address-formatter` is imported with a default module
// but there is none according to the type information, to resolve this we check if frag.default.format exists
// if it doesn't we assume it is frag.format, this was checked to be working with both build/serve commands
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const format: typeof frag.format = frag?.default?.format ?? frag?.format;

function isGooglePlaceResult(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any,
): result is google.maps.places.PlaceResult {
  if ('name' in result) {
    return true;
  }

  return false;
}

type AddressFormatOptions = Partial<{
  excludeLocationName: boolean;
}>;

export class AddressHelper {
  static convertToAddress(
    googleAddress: google.maps.places.PlaceResult | google.maps.GeocoderResult,
  ): ChainCargoAddress | null {
    const address: Partial<ChainCargoAddress> = {};
    if (googleAddress.address_components) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const addressComponent of googleAddress?.address_components) {
        if (
          !address.number &&
          addressComponent.types.includes('street_number')
        ) {
          const streetNumber = addressComponent.long_name;
          const numberMatch = streetNumber.match(/^(\d+)(.*)$/); // Match street number followed by any characters (e.g. 123A)
          const unitMatch = streetNumber.match(/^(.*?)(\d+.*)$/); // Match any characters followed by street number (e.g. A123,unit 14)

          if (numberMatch) {
            address.number = numberMatch[1];
            address.numberAddition = numberMatch[2].trim();
          } else if (unitMatch) {
            address.number = unitMatch[1].trim();
            address.numberAddition = unitMatch[2].trim();
          } else {
            address.number = streetNumber;
            address.numberAddition = '';
          }
          // tslint:disable-next-line:max-line-length
        } else if (
          !address.street &&
          (addressComponent.types.includes('route') ||
            addressComponent.types.includes('sublocality_level_2') ||
            addressComponent.types.includes('sublocality'))
        ) {
          address.street = addressComponent.long_name;
        } else if (
          !address.city &&
          (addressComponent.types.includes('locality') ||
            addressComponent.types.includes('postal_town'))
        ) {
          address.city = addressComponent.long_name;
        } else if (
          !address.state &&
          addressComponent.types.includes('administrative_area_level_1')
        ) {
          address.state = addressComponent.long_name;
        } else if (
          !address.country &&
          addressComponent.types.includes('country')
        ) {
          address.country = addressComponent.long_name;
          address.countryCode = addressComponent.short_name;
        } else if (
          !address.zipCode &&
          addressComponent.types.includes('postal_code')
        ) {
          address.zipCode = String(addressComponent.long_name).replace(
            /\s/g,
            '',
          );
        }
      }
    }

    if (
      isGooglePlaceResult(googleAddress) &&
      googleAddress.name &&
      googleAddress.name !== `${address.street} ${address.number}`
    ) {
      address.locationName = googleAddress.name;
    }

    const latitude = googleAddress?.geometry?.location?.lat();
    const longitude = googleAddress?.geometry?.location?.lng();

    if (typeof latitude === 'number' && typeof longitude === 'number') {
      address.latitude = latitude;
      address.longitude = longitude;
    }

    if (Object.keys(address).length > 0) {
      return address as ChainCargoAddress;
    }

    return null;
  }

  private static createAddressInput(
    address: ChainCargoAddress,
    options: AddressFormatOptions = {},
  ): frag.Input {
    // smaller inline helper function to make sure we output a string
    // but null and undefined would return 'null' 'undefined' with the string function so we make sure we emit those as a empty string
    const _string = (s: string | null | undefined) => {
      return s ? String(s) : '';
    };

    return {
      attention: options?.excludeLocationName
        ? ''
        : _string(address?.locationName),
      housenumber: `${_string(address?.number)}${_string(
        address?.numberAddition ?? undefined,
      )}`,
      street: _string(address?.street),
      road: _string(address?.street),
      city: _string(address?.city),
      postcode: _string(address?.zipCode),
      state: _string(address?.state),
      country: _string(address?.country),
      countryCode: _string(address?.countryCode),
    };
  }

  static formatAddress(
    address: ChainCargoAddress | null | undefined,
    config: AddressFormatOptions = {},
  ) {
    if (!address) {
      return '';
    }

    return format(AddressHelper.createAddressInput(address, config));
  }

  static formatAddressToLines(
    address: ChainCargoAddress | null | undefined,
    config: AddressFormatOptions = {},
  ) {
    if (!address) {
      return [];
    }

    return format(AddressHelper.createAddressInput(address, config), {
      output: 'array',
    }).filter((x) => x?.length);
  }

  static isValidAddress(address: ChainCargoAddress | null) {
    if (!address) {
      return false;
    }

    if (
      address.source === LocationSource.GoogleMap ||
      address.source === LocationSource.Backend
    ) {
      return true;
    }

    const requiredFields = ['country', 'city', 'street'];
    for (const [key, value] of Object.entries(address)) {
      if (requiredFields.includes(key) && !value) {
        return false;
      }
    }

    if (address.number || address.locationName) {
      return true;
    } else {
      return false;
    }
  }

  static getAddressOptions(): google.maps.places.AutocompleteOptions & {
    origin: undefined;
  } {
    return {
      types: [],
      strictBounds: true,
      origin: undefined,
    };
  }
}

export function shortAddress(address: AddressViewModel | undefined) {
  if (address?.countryCode === 'US' || address?.countryCode === 'AU') {
    return (
      //city or street when city is not there
      (address?.city
        ? address?.city + ', '
        : address.street
          ? address.street + ', '
          : '') +
      //stateCODE
      (address.stateCode ? address.stateCode + ' ' : '') +
      //zipcode
      (address.zipCode ? address.zipCode : '') +
      ', ' +
      //country code
      address?.countryCode
    );
  } else {
    return (
      //zipcode
      (address?.zipCode ? address.zipCode + ' ' : '') +
      //city or street when city is not there or state when both city and street are not there
      (address?.city
        ? address?.city + ', '
        : address?.street
          ? address.street + ', '
          : address?.state
            ? address.state + ', '
            : '') +
      // country
      address?.countryCode
    );
  }
}
