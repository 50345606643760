import { HttpHeaders } from '@angular/common/http';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type FilterInfo,
  type id,
} from '@cca-infra/common';
import {
  type OrderCarrierDataViewModel,
  type OrderActionsViewModel,
  type CarrierToRemovalModel,
  type SequenceType,
  type TerminateAutoOfferRequest,
} from '../model';
import { Injectable } from '@angular/core';

export interface CarrierAssignOther {
  orderId: id;
  carrierGroupId: id;
  carrierRate: number;
}

export interface CarrierAssignTender {
  orderId: id;
  carrierGroupId: id;
  tenderRequestRowCarrierId: id;
}

export interface CarrierAssignLane {
  orderId: id;
  carrierGroupId: id;
  laneCarrierRowId?: id;
}

export interface CarrierAssignAuto {
  orderId: id;
  autoAssignActive: true;
  laneCarriersAutoAssigns: {
    delay: number;
    index: number;
    laneCarrierRowId: id | null | undefined;
    userGroupId: id;
  }[];
  tenderCreationDelay: number;
}

export type CarrierAssign =
  | CarrierAssignOther
  | CarrierAssignTender
  | CarrierAssignLane
  | CarrierAssignAuto;

export type CarrierAutoAssignStatus = {
  carrierGroupId: id;
  carrierGroupName: id;
  laneCarrierRowId: id;
  laneRate: number;
  status: string;
  statusValue: AutoOfferStatus;
  triggerOfferDate: number;
};

export enum AutoOfferStatus {
  Offered = 2,
  Inactive = 1,
  Cancelled = 3,
}

@Injectable({
  providedIn: 'root',
})
export class OrderActionsService extends BaseApiService {
  constructor() {
    super(`order.v1.orderActions`);
  }

  removeCarrier(orderId: id) {
    return this.http.post(`${this.url}remove-carrier`, {
      orderId,
    });
  }

  removeAssignedCarriers(orderId: id, carrierIds: string[]) {
    return this.http.post(`${this.url}remove-assigned-carriers`, {
      orderId,
      carrierGroupIds: carrierIds,
    });
  }

  /**
   * Pods actions below:
   */
  uploadPod(orderId: string, file: File, legId: string) {
    const formData: FormData = new FormData();
    formData.set('UploadedFile', file, file.name);
    formData.set('OrderId', orderId);
    formData.set('LegId', legId);

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');
    return this.http.post(`${this.url}pod-upload`, formData, {
      headers: headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  getCarrierData(actionId: string) {
    const url = `${this.url}get-carrier-data/${actionId}`;
    return this.http.get<OrderCarrierDataViewModel>(url);
  }

  removalCarrierPaginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<CarrierToRemovalModel>>(
      `${this.url}removal-carriers-paginated`,
      { ...AdaptPaginationRequest(paginationRequest), ...extraParams },
    );
  }

  terminateAutoOffering(orderId: string, sequenceType: SequenceType) {
    return this.http.post<TerminateAutoOfferRequest>(
      `${this.url}terminate-auto-offer`,
      {
        orderId,
        sequenceType,
      },
    );
  }

  getAutoAssignStatus(orderId: string, sequenceType: SequenceType) {
    return this.http.post<CarrierAutoAssignStatus[]>(
      `${this.url}get-auto-assign-status`,
      {
        orderId,
        sequenceType,
      },
    );
  }

  orderPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<OrderActionsViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  orderPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-filters`);
  }
}
