import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type UpdateLaneRowEquipmentV2Request,
  type UpdateLaneRowParkingRequirementsV2Request,
  type UpdateLaneRowMinimumInsuranceV2Request,
  type UpdateLaneRowPolicyDetailsV2Request,
  type UpdateLaneRowExternalContactV2Request,
  type GetLanesForCreateOfferV2Request,
  type LanesRowPaginationItemV2,
  type GetLanesTenderForCreateV2Request,
  type RespondToOfferV2Request,
  type LaneRowOfferV2ViewModel,
  type LanesDetailsForCreateV2ViewModel,
} from '../model';
import {
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  AdaptPaginationRequest,
} from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class LaneRequestV2Service extends BaseApiService {
  constructor() {
    super('request.v2.laneRequest');
  }

  laneDetailsForOfferCreate(request: GetLanesForCreateOfferV2Request) {
    return this.http.post(
      `${this.url}lane-row/details-for-offer-creation`,
      request,
    );
  }

  laneRowsPaginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<LanesRowPaginationItemV2>>(
      `${this.url}lane-row/paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  laneDetailsForTenderCreate(request: GetLanesTenderForCreateV2Request) {
    return this.http.post<LanesDetailsForCreateV2ViewModel>(
      `${this.url}lane-row/detail-for-create-tender`,
      request,
    );
  }

  respondToOffer(request: RespondToOfferV2Request) {
    return this.http.post<LaneRowOfferV2ViewModel>(
      `${this.url}lane-row/respond-to-offer`,
      request,
    );
  }

  updateEquipment(request: UpdateLaneRowEquipmentV2Request) {
    return this.http.post(`${this.url}lane-row/update-equipment`, request);
  }

  updateParking(request: UpdateLaneRowParkingRequirementsV2Request) {
    return this.http.post(
      `${this.url}lane-row/update-parking-requirements`,
      request,
    );
  }

  updateInsurance(request: UpdateLaneRowMinimumInsuranceV2Request) {
    return this.http.post(
      `${this.url}lane-row/update-minimum-insurance`,
      request,
    );
  }

  updatePolicyDetails(request: UpdateLaneRowPolicyDetailsV2Request) {
    return this.http.post(`${this.url}lane-row/update-policy-details`, request);
  }

  updateContacts(request: UpdateLaneRowExternalContactV2Request) {
    return this.http.post(
      `${this.url}lane-row/update-external-contacts`,
      request,
    );
  }
}
