import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  type FilterChoiceOption,
  type FilterInfo,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';
import {
  type FilterInformation,
  type AcceptSelectedLaneRowBidsRequest,
  type AuditLogItemViewModel,
  type CarrierGroupOptionsViewModel,
  type CreateLanesTenderRequest,
  type LanesRequestTenderDetailViewModel,
  type PaginationLanesTenderItemViewModel,
  type ReadLaneTenderAuditLogsRequest,
  type RespondNotInterestedToLaneTenderRequest,
  type SelectLaneRowBidRequest,
  type UnselectLaneRowBidRequest,
  type UpdateLanesTenderRequest,
  type GetLaneRowBidsRequest,
  type LaneRowBidViewModel,
  type LaneRowTenderDetailViewModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class TenderLaneService extends BaseApiService {
  constructor() {
    super(`tender.v1.lane`);
  }

  getLaneRowTenderDetails(laneRowId: id) {
    return this.http.get<LaneRowTenderDetailViewModel>(
      `${this.url}lane-row-tender-details/${laneRowId}`,
    );
  }

  getLaneRowBids(request: GetLaneRowBidsRequest) {
    return this.http.post<LaneRowBidViewModel[]>(
      `${this.url}lane-row-bids`,
      request,
    );
  }

  tenderPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<PaginationLanesTenderItemViewModel>
    >(`${this.url}paginated`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  allPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-filters`);
  }

  laneTenderPaginationForCarriers(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<PaginationLanesTenderItemViewModel>
    >(`${this.url}paginated-carrier-lanesTender`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  laneTenderPaginationForCarriersFilters() {
    return this.http.get<FilterInformation>(
      `${this.url}paginated-carrier-laneTender-filters`,
    );
  }

  laneRowTenderPaginationFilters() {
    return this.http.get(`${this.url}paginated-lane-tender-row-filters`);
  }

  laneRowTenderPaginationFiltersUnauthorized() {
    return this.http.get(
      `${this.url}paginated-lane-tender-row-filters-unauthorized`,
    );
  }

  createTenderRequest(request: CreateLanesTenderRequest) {
    return this.http.post(`${this.url}create`, request);
  }

  updateTenderRequest(request: UpdateLanesTenderRequest) {
    return this.http.post(`${this.url}update`, request);
  }

  closeTenderRequest(request: { tenderId: id }) {
    return this.http.post(`${this.url}close`, request);
  }

  notInterested(request: RespondNotInterestedToLaneTenderRequest) {
    return this.http.post(`${this.url}respond-not-interested`, request);
  }

  notInterestedUnauthorized(request: RespondNotInterestedToLaneTenderRequest) {
    return this.http.post(
      `${this.url}respond-not-interested-unauthorized`,
      request,
    );
  }

  getCarrierGroupOptionsForCarrier() {
    return this.http.get<CarrierGroupOptionsViewModel[]>(
      `${this.url}get-carrier-group-options-for-carrier`,
    );
  }

  getEventLog(request: ReadLaneTenderAuditLogsRequest) {
    return this.http.post<AuditLogItemViewModel[]>(
      `${this.url}read-lanerequest-auditlogs`,
      request,
    );
  }

  unSaveSelectedCarrierBid(request: SelectLaneRowBidRequest) {
    return this.http.post<boolean>(`${this.url}unselect-lane-row-bid`, request);
  }

  readyToOffer(request: AcceptSelectedLaneRowBidsRequest) {
    return this.http.post<boolean>(
      `${this.url}acccept-selected-lane-row-bids`,
      request,
    );
  }

  getLaneRequestTenderTabDetails(laneRequestId: id) {
    return this.http.get<LanesRequestTenderDetailViewModel>(
      `${this.url}lanes-request-tender-details/${laneRequestId}`,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  getLaneTenderRowFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}paginated-lane-tender-row/get-filter-options/${filterKey}`,
    );
  }

  saveSelectedCarrierBid(request: UnselectLaneRowBidRequest) {
    return this.http.post<boolean>(`${this.url}select-lane-row-bid`, request);
  }
}
