import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { type TimeSlotUnauthorizedViewModel } from '../model';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderPublicsService extends BaseApiService {
  constructor() {
    super(`order.v1.public`);
  }

  getTimeslotData(hash: string, lang?: string) {
    let url = `${this.url}get-timeslot-data/${hash}`;
    if (lang) {
      url += `/${lang}`;
    }
    return this.http.get<TimeSlotUnauthorizedViewModel>(url);
  }

  completeOrderCarrierAssignment(
    actionId: string,
    orderId: string,
    accepted: boolean,
    hash?: string,
  ) {
    return this.http.post(`${this.url}complete-order-carrier-assignment/`, {
      hash: hash,
      orderId: orderId,
      actionId: actionId,
      accepted: accepted,
    });
  }

  timeslotConfirm(
    returnEndpoint: string | null | undefined,
    body: {
      hash: string;
      orderId: string;
      timeSlotWindowStart: number;
      timeSlotWindowEnd: number;
      additionalInstructionsComment: string | null;
    },
  ) {
    if (returnEndpoint) {
      return this.http.post(`${this.url}${returnEndpoint}`, body);
    }
    return EMPTY;
  }
}
