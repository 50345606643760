import { Injectable } from '@angular/core';
import {
  AdaptPaginationRequest,
  type AuditLogItemViewModel,
  type FilterChoiceOption,
  type FilterInfo,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import {
  type LaneRowDetailViewModel,
  type LanesRequestDetailViewModel,
  type LanesRequestPaginationItem,
  type RequestContactViewModel,
  type UpdateLaneRowCargoInformationRequest,
  type UpdateLaneRowLocationRequest,
  type UpdateLaneRowToInternallyRefusedRequest,
  type UpdateLaneRowVolumeFrequencyRequest,
  type UpdateLanesRequestRequest,
  type SetLaneRowsOfferedPricesRequest,
  type LaneRowOfferViewModel,
  type GenerateLanesRequest,
  type ShipmentTypesViewModel,
  type CreateLanesRequestRequest,
  type UpdateLaneRowVehicleRequest,
  type UpdateLaneRowAdditionalDetailsRequest,
} from '../model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LaneRequestService extends BaseApiService {
  constructor() {
    super('request.v1.laneRequest');
  }

  laneRowsExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}lane-row/export`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
        observe: 'response',
      },
    );
  }

  updateVehicle(request: UpdateLaneRowVehicleRequest) {
    return this.http.post(`${this.url}lane-row/update-vehicle`, request);
  }

  laneRowDetailUnauthorized(laneRowId: id, hash: string) {
    return this.http.get<LaneRowDetailViewModel>(
      `${this.url}lane-row/detail-unauthorized/${laneRowId}/${hash}`,
    );
  }

  laneRowDetail(laneRowId: id) {
    return this.http.get<LaneRowDetailViewModel>(
      `${this.url}lane-row/detail/${laneRowId}`,
    );
  }

  create(sequenceId: id) {
    return this.http.post<string>(`${this.url}lane-row/create`, {
      userSequenceStateId: sequenceId,
    });
  }

  createLaneRequest(request: CreateLanesRequestRequest) {
    return this.http.post<LanesRequestDetailViewModel>(
      `${this.url}create`,
      request,
    );
  }

  allPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<LanesRequestPaginationItem>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  allPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-filters`);
  }

  laneRowsPaginatedFilters() {
    return this.http.get<FilterInfo>(`${this.url}lane-row/paginated-filters`);
  }

  laneRowDuplicate(laneRowId: id) {
    return this.http.post<{
      referenceNumber: string;
      id: id;
    }>(`${this.url}lane-row/duplicate`, {
      laneRowId: laneRowId,
    });
  }

  offersPaginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<LaneRowOfferViewModel>>(
      `${this.url}lane-row/paginated-offers`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  getLaneRequestDetail(referenceNumber: string) {
    return this.http.get<LanesRequestDetailViewModel>(
      `${this.url}detail/${referenceNumber}`,
    );
  }

  updateLaneRequest(request: UpdateLanesRequestRequest) {
    return this.http.post<LanesRequestDetailViewModel>(
      `${this.url}update`,
      request,
    );
  }

  pushLaneRequest(laneRequestId: id) {
    return this.http.post<LanesRequestDetailViewModel>(`${this.url}push`, {
      lanesRequestId: laneRequestId,
    });
  }

  pushBackLaneRequest(laneRequestId: id, pushBackReason: string) {
    return this.http.post(`${this.url}push-back`, {
      laneRequestId: laneRequestId,
      pushBackReason: pushBackReason,
    });
  }

  setReminderLaneRequest(laneRequestId: id, reminderDate: Date) {
    return this.http.post(`${this.url}set-reminder`, {
      laneRequestId: laneRequestId,
      reminderDate: reminderDate,
    });
  }

  acceptLaneRequest(laneRequestId: id) {
    return this.http.post<LanesRequestDetailViewModel>(`${this.url}accept`, {
      laneRequestId: laneRequestId,
    });
  }

  cancelLaneRequest(laneRequestId: id) {
    return this.http.post<LanesRequestDetailViewModel>(`${this.url}cancel`, {
      lanesRequestId: laneRequestId,
    });
  }

  getEventLog(laneRequestId: id) {
    return this.http.get<AuditLogItemViewModel[]>(
      `${this.url}eventlog/${laneRequestId}`,
    );
  }

  getContacts(laneRequestId: id) {
    return this.http.get<RequestContactViewModel[]>(
      `${this.url}contacts/${laneRequestId}`,
    );
  }

  updateVolumeFrequency(request: UpdateLaneRowVolumeFrequencyRequest) {
    return this.http.post<boolean>(
      `${this.url}lane-row/update-volume-frequency`,
      request,
    );
  }

  updateCargo(request: UpdateLaneRowCargoInformationRequest) {
    return this.http.post(
      `${this.url}lane-row/update-cargo-information`,
      request,
    );
  }

  updateLocation(request: UpdateLaneRowLocationRequest) {
    return this.http.post(`${this.url}lane-row/update-location`, request);
  }

  deleteRow(lanRowId: id) {
    return this.http.delete<boolean>(`${this.url}lane-row/${lanRowId}`);
  }

  refuseToServeToSales(request: UpdateLaneRowToInternallyRefusedRequest) {
    return this.http.post<LaneRowDetailViewModel>(
      `${this.url}lane-row/update-to-internally-refused`,
      request,
    );
  }

  getTenderOwner(laneRowId: id) {
    return this.http.get<RequestContactViewModel>(
      `${this.url}lane-row/tender-owner/${laneRowId}`,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  lanesSetOfferedPrices(request: SetLaneRowsOfferedPricesRequest) {
    return this.http.post(`${this.url}lane-row/set-offered-prices`, request);
  }

  generateLanes(request: GenerateLanesRequest) {
    return this.http.post(`${this.url}lane-row/generate-lanes`, request);
  }

  getLaneRequestServiceTypes() {
    return this.http.get<ShipmentTypesViewModel>(
      `${this.url}shipment-type-options`,
    );
  }

  updateAddnlDetails(addnlDetails: UpdateLaneRowAdditionalDetailsRequest) {
    return this.http.post(
      `${this.url}lane-row/update-additional-details`,
      addnlDetails,
    );
  }

  downloadLaneMassUploadTemplate(laneReqeuestId: string) {
    return this.http.get(`${this.url}download/template/${laneReqeuestId}`, {
      responseType: 'arraybuffer',
    });
  }

  uploadLanes(laneRequestId: string, file: File) {
    const formData = new FormData();
    formData.set('file', file, file.name);

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');

    return this.http.post(
      `${this.url}lane-row/excelImport/${laneRequestId}`,
      formData,
      { headers },
    );
  }
}
