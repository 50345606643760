import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { type ngClassInput } from '@cca-common/cdk';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import DOMPurify from 'dompurify';
import { ShowMoreLessComponent } from '../show-more-less';
import { HtmlViewerComponent } from './html-viewer.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslationService } from '@cca-infra/translation-management/v1';
import { take } from 'rxjs';
import { TranslateButtonComponent } from '../translate-button';

@Component({
  selector: 'cca-rich-text-viewer',
  imports: [
    NgClass,
    TranslocoModule,
    ShowMoreLessComponent,
    HtmlViewerComponent,
    MatButtonModule,
    TranslateButtonComponent,
  ],
  templateUrl: './rich-text-viewer.component.html',
  styleUrl: './rich-text-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('richTextViewer'), TranslationService],
})
export class RichTextViewerComponent {
  translationService = inject(TranslationService);

  title = input<string | null>(null);
  text = input<string | undefined | null>(null);
  styling = input<ngClassInput>('');
  large = input<boolean>(false);
  translate = input<boolean | null>(null);
  internalTranslation = input<boolean | null>(null);

  safeText = computed<string | TrustedHTML>(() => {
    const text = this.text();

    if (!text) {
      return '';
    }

    // note for some reason, quil removes all <p><br/></p> and replaces them with empty <p></p>
    // This makes where we would render this html be missing those line breaks
    // therefore we are replacing them here once more
    return DOMPurify.sanitize(text.replaceAll('<p></p>', '<p></br></p>'), {
      RETURN_TRUSTED_TYPE: true,
    });
  });

  translatedTextRaw = signal<string | TrustedHTML | null>(null);
  translatedText = computed<string | TrustedHTML>(() => {
    return this.translatedTextRaw()
      ? DOMPurify.sanitize(this.translatedTextRaw() as string, {
          RETURN_TRUSTED_TYPE: true,
        })
      : this.safeText();
  });
  isOverflow = signal(false);
  isExpanded = false;
  originallyExpanded = this.isExpanded;
  liveTranslationToggle = false;

  constructor() {
    effect(() => {
      this.text();
      this.originallyExpanded = this.isExpanded;
      this.isExpanded = false;
    });

    effect(() => {
      const translate = this.translate();

      if (translate !== null) {
        this._translate(translate);
      }
    });
  }

  onHtmlContainerDimensionsChange(event: {
    scrollHeight: number;
    clientHeight: number;
  }) {
    this.isOverflow.set(event.scrollHeight > event.clientHeight);
    this.isExpanded = this.originallyExpanded;
  }

  onLiveTranslation() {
    this._translate(!this.liveTranslationToggle);
    this.liveTranslationToggle = !this.liveTranslationToggle;
  }

  private _translate(translate: boolean) {
    if (translate) {
      this.translationService
        .liveTranslate({ sourceValue: this.safeText() as string })
        .pipe(take(1))
        .subscribe((result) => {
          this.translatedTextRaw.set(result);
        });
    } else {
      this.translatedTextRaw.set(this.safeText() as string);
    }
    this.isExpanded = false;
  }
}
