<div class="grid gap-2">
  <div class="flex flex-col gap-2">
    @for (vehicle of vehicles(); track vehicle.vehicleTypeId) {
      <div class="flex flex-row items-center gap-2">
        <div class="font-medium">
          {{ vehicle.vehicleTypeName }}
        </div>
        <div class="flex flex-row gap-2">
          @for (badge of vehicle.bodyTypes; track badge) {
            <cca-label-badge
              flavor="highlight"
              [labelName]="badge?.bodyTypeName ?? ''"
            ></cca-label-badge>
          }
        </div>
      </div>
    }
  </div>
</div>
