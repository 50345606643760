import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * this component is used to make sure the routing is working ( it needs a component/redirect etc else the configuration is invalid)
 */
@Component({
  selector: 'cca-noop',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoopComponent {}
