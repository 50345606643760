import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { type StatusBadgeFlavor } from '@cca-common/core';

@Component({
  imports: [NgClass],
  selector: 'cca-numerical-badge',
  styleUrls: ['./numerical-badge.component.scss'],
  templateUrl: './numerical-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiNumericalBadgeComponent {
  readonly flavor = input<StatusBadgeFlavor>('primary');
  readonly number = input<number | string>('');
  readonly smallBadge = input(false);
}
