import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  type RespondToLaneTenderV2Request,
  type LaneRowBidV2ViewModel,
  type LanesTenderV2ViewModel,
} from '../model';
import { type id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class TenderLaneV2Service extends BaseApiService {
  constructor() {
    super(`tender.v2.lane`);
  }

  laneTenderRowBidUnauthorized(
    tenderReferenceNumber: string,
    laneRowId: id,
    hash: string,
  ) {
    return this.http.get<LaneRowBidV2ViewModel>(
      `${this.url}lane-row-bid-unauthorized/${tenderReferenceNumber}/${laneRowId}/${hash}`,
    );
  }

  getTenderByReference(tenderId: id) {
    return this.http.get<LanesTenderV2ViewModel>(
      `${this.url}detail-by-reference/${tenderId}`,
    );
  }

  laneTenderRowBid(tenderRequestRowCarrierId: id) {
    return this.http.get<LaneRowBidV2ViewModel>(
      `${this.url}lane-row-bid/${tenderRequestRowCarrierId}`,
    );
  }

  respond(request: RespondToLaneTenderV2Request) {
    return this.http.post(`${this.url}respond`, request);
  }

  respondUnauthorized(request: RespondToLaneTenderV2Request) {
    return this.http.post(`${this.url}respond-unauthorized`, request);
  }
}
