import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  type FilterChoiceOption,
  type FilterInfo,
  type PaginationRequest,
  type PaginationRequestParameters,
  type PaginationResponse,
  type id,
} from '@cca-infra/common';
import {
  type ExternalManagedTransUserGroupsViewModel,
  type GetAllCarrierGroupsForManagedTransportTenderExternalRequest,
  type ManagedTransTenderIterationsItemViewModel,
  type ManagedTransportTenderIterationBasicDetailExternalViewModel,
  type ManagedTransportTenderIterationBasicDetailInternalViewModel,
  type RequestTenderBidsViewModel,
  type ShipmentItem,
  type TenderCarriersViewModel,
  type TenderIterationsItemViewModel,
  type TenderSideSummaryViewModel,
} from '../model';

export interface DefaultTenderRequestModel {
  requestId: string | null;
  orderId: string | null;
}

@Injectable({ providedIn: 'root' })
export class TenderService extends BaseApiService {
  constructor() {
    super(`tender.v1.request`);
  }

  tenderPaginationFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  shipmentPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<ShipmentItem>>(
      `${this.url}paginated-shipments`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  tenderDetailPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<TenderIterationsItemViewModel>>(
      `${this.url}paginated-tender-iterations`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  tenderDetailPaginationFilters() {
    return this.http.get<FilterInfo>(`${this.url}paginated-tenders-filters`);
  }

  tenderMTDetailPaginationFilters() {
    return this.http.get<FilterInfo>(
      `${this.url}paginated-tender-iteration-filters`,
    );
  }

  tenderDetailPaginationMT(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<
      PaginationResponse<ManagedTransTenderIterationsItemViewModel>
    >(`${this.url}paginated-managed-trans-tender-iterations`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  tenderSideSummary(tenderReferenceNumber: string) {
    return this.http.post<TenderSideSummaryViewModel>(
      `${this.url}get-tender-side-summary`,
      {
        tenderReferenceNumber,
      },
    );
  }

  getShipmentCarriers(tenderRequestId: id) {
    return this.http.get<TenderCarriersViewModel>(
      `${this.url}get-carriers/${tenderRequestId}`,
    );
  }

  getTenderBidsByOrderId(orderId: id) {
    return this.http.get<RequestTenderBidsViewModel>(
      `${this.url}get-request-tender-bids-order/${orderId}`,
    );
  }

  getTenderBidsByRequestId(requestId: id) {
    return this.http.get<RequestTenderBidsViewModel>(
      `${this.url}get-request-tender-bids-request/${requestId}`,
    );
  }

  getDefaultComment(request: DefaultTenderRequestModel) {
    return this.http.post<string>(
      `${this.url}get-default-tender-comment`,
      request,
    );
  }

  tenderDetailMTInternal(requestIterationId: string) {
    return this.http.post<ManagedTransportTenderIterationBasicDetailInternalViewModel>(
      `${this.url}get-internal-managed-transport-tender-iteration-basic-details`,
      {
        requestIterationId,
      },
    );
  }

  tenderDetailMT(requestIterationId: string) {
    return this.http.post<ManagedTransportTenderIterationBasicDetailExternalViewModel>(
      `${this.url}get-external-managed-transport-tender-iteration-basic-details`,
      {
        requestIterationId,
      },
    );
  }

  getTenderDetailMTcarrierGroups(
    body: GetAllCarrierGroupsForManagedTransportTenderExternalRequest,
  ) {
    return this.http.post<ExternalManagedTransUserGroupsViewModel[]>(
      `${this.url}get-all-carrier-groups-for-managed-trans-tender-external`,
      body,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }
}
